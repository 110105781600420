/** @jsxImportSource theme-ui */
import React from "react";
import Link from "../../components/Link";
import Event from "./Event";
import { keyframes } from "@emotion/react";

const cardEntryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-100%, 0) scale(0.3);
  }
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1.0);
  }
`;

const nthChildAnimations = Array.from({ length: 20 }, (_, i) => ({
  [`:nth-of-type(${i + 1})`]: {
    animationDelay: `${50 * i}ms`,
  },
})).reduce((a, b) => Object.assign(a, b), {});

const EventsList = ({ events, ...props }) => (
  <React.Fragment {...props}>
    {events.map((e) => (
      <Link
        to={e.slug}
        variant="cards.events.link"
        sx={{
          animation: `${cardEntryAnimation} .5s ease-out`,
          animationFillMode: "backwards",
          ...nthChildAnimations,
        }}
        key={e.event_key}
      >
        <Event {...e} />
      </Link>
    ))}
  </React.Fragment>
);

export default EventsList;
