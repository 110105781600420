import { stripPrefix } from "../../utils/stringUtils";

const prepareEventsList = (events) => {
  events.sort((a, b) => {
    const start_a = a.start.date || a.start.dateTime;
    const start_b = b.start.date || b.start.dateTime;
    return start_a > start_b;
  });
  events.forEach((event) => {
    event.summary = stripPrefix(event.summary, " - ");
    event.calendars.sort((a, b) => a.priority >= b.priority);
  });
  return events;
};

export default prepareEventsList;
