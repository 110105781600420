/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CurrentPage from "../../components/CurrentPage";
import Pagination from "../../components/Pagination";
import EventsList from "./EventsList";
import prepareEventsList from "./prepareEventsList";
import Breadcrumbs from "../../components/Breadcrumbs";
import Seo from "../../components/Seo";
import CalendarDownloadButton from "../../components/CalendarDownloadButton";
import { Card } from "theme-ui";
import { Themed } from "@theme-ui/mdx";

const PaginationTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo
        title="Termine"
        pathname={location.pathname}
        description="Bevorstehende Übungen und Termine der Feuerwehr Arborn und des Vereins sowie Festausschuss."
      />

      <Layout>
        <Breadcrumbs names={["Termine"]} location={location} />

        <Themed.h2>Bevorstehende Termine</Themed.h2>

        <Card
          variant="default"
          sx={{ maxWidth: "container", p: 4, bg: "shade4", mb: 4 }}
        >
          <Themed.p>
            Die Termine sind auch als Kalender-Dateien (.ics) verfügbar. Über
            den folgenden Button gelangst du direkt zu den einzelnen Kalendern
            und kannst diese sofort herunterladen:
          </Themed.p>
          <CalendarDownloadButton variant="secondary">
            Kalender herunterladen
          </CalendarDownloadButton>
        </Card>

        <CurrentPage pageContext={pageContext} />
        <div
          sx={{
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(480px, 100%), 1fr))",
            gridGap: 4,
          }}
        >
          <EventsList events={prepareEventsList(data.calendar.events)} />
        </div>
        <Pagination path="/termine" {...pageContext} />
      </Layout>
    </>
  );
};

export default PaginationTemplate;

export const eventsPaginationQuery = graphql`
  query EventsPagination($pageStartDate: String!, $limit: Int!) {
    calendar {
      events: getEvents(
        input: { after_datetime: $pageStartDate, limit: $limit }
      ) {
        slug
        summary
        start {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        end {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        event_key
        calendars {
          event_label
          color
          priority
        }
      }
    }
  }
`;
