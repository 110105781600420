/** @jsxImportSource theme-ui */
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Link from "./Link";
import { Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CARET_SIZE_PX = 12;

const DownloadButtonTemplate = ({ calendars, children, ...props }) => (
  <div
    sx={{
      position: "relative",
      display: "inline-block",
    }}
  >
    <Button
      variant="secondary"
      {...props}
      sx={{
        "&:focus-within > div": {
          display: "flex",
          visibility: "visible",
          opacity: 1,
        },
        " > div:focus": {
          display: "flex",
          visibility: "visible",
          opacity: 1,
        },
      }}
    >
      {children} <FontAwesomeIcon icon="chevron-down" />
      <div
        sx={{
          zIndex: "dropdownList",
          display: "none",
          visibility: "hidden",
          opacity: 0,
          position: "absolute",
          left: 0,
          top: "calc(100% + 10px)",
          minWidth: "100%",
          flexDirection: "column",
          backgroundColor: "background",
          border: (t) => `1px solid ${t.colors.shade5}`,
          borderRadius: "medium",
          "&:after": {
            position: "absolute",
            top: `-${CARET_SIZE_PX}px`,
            right: "10%",
            content: "''",
            width: 0,
            height: 0,
            borderLeft: `${CARET_SIZE_PX}px solid transparent`,
            borderRight: `${CARET_SIZE_PX}px solid transparent`,
            borderBottom: (t) =>
              `${CARET_SIZE_PX}px solid ${t.colors.background}`,
          },
        }}
      >
        {calendars.map(({ public_url, long_name }) => (
          <Link
            href={public_url}
            variant="dropdown"
            target="_blank"
            key={`cal-dl-${public_url}`}
          >
            {long_name}
          </Link>
        ))}
      </div>
    </Button>
  </div>
);

const CalendarDownloadButton = (args) => (
  <StaticQuery
    query={graphql`
      query GetCalendars {
        calendar {
          calendars: getAllCalendars {
            public_url
            long_name
          }
        }
      }
    `}
    render={(data) => {
      const { calendars } = data.calendar;
      return DownloadButtonTemplate({ ...args, calendars });
    }}
  />
);

export default CalendarDownloadButton;
